import React from "react";

import Layout from "../components/Layout";
import Container from "../components/Container";
import SEO from "../components/seo";
import { Link } from 'gatsby'

const ImprintPage = () => (
  <Layout page="subpage">
    <SEO
      title="Home"
      keywords={[`heizkostenabrechnung`, `heizkosten`, `smart home`]}
    />
    <Container>
      <h1>Teilnahmebedingungen Verlosung zur emma Umfrage</h1>
      <h2>Veranstalter der Verlosung</h2>
      <p>
        Robin Heat GmbH
        <br />
        Baumhofstraße 45
        <br />
        44799 Bochum
        <br />
        Amtsgericht Bochum HRB 17667
        <br />
        E-Mail: alexander.drossel(at)getemma.io
      </p>
      <p>
        <strong>Geschäftsführer</strong>
        <br />
        Alexander Droßel
        <br />
        Armand Zohari
      </p>
      <h2>Teilnahmeschluss</h2>
      <p>
        Die Anmeldung zur Verlosung startet am 11.09.2019 und endet am 10.11.2019 um 24:00 Uhr Deutscher Zeit.<br />
      </p>
      <h2>Teilnahmebedingungen</h2>
      <p>
        Teilnahmeberechtigt ist jeder, der an unserer Umfrage teilnimmt und uns seine E-Mail Adresse hinterlässt.
        Mindestalter zur Verlosung sind 16 Jahre.
      </p>
      <h2>Ermittlung des Gewinners</h2>
      <p>
        Nach Ende des Anmeldezeitraums wird nach Zufallsprinzip der Gewinn an alle Teilnehmer ausgelost.
        Ein doppelter Gewinn ist nicht möglich.
        Eine mehrfache Anmeldung ist nicht möglich uns führt zum Ausschluss dieser Person aus dem Verfahren.
        Nach der Verlosung werden die Gewinner per Mail kontaktiert und nach weiteren Kontaktdaten gefragt.
        Die Gewinner werden weiterhin auf unserer Facebook Seite getemma.io bekanntgegeben.
      </p>
      <h2>Preise</h2>
      <p>
        Als Preise werden insgesamt 12 Amazon Gutscheine verlost.
        Darunter 2 x 50 Euro und 10 x 10 Euro Gutscheine.
      </p>
      <h2>Ausserordentliche Beendigung der Verlosung</h2>
      <p>
        Sollte es aus uns nicht beeinflussbaren Gründen nicht möglich sein die Verlosung durchzuführen,
        so behalten wir uns das Recht vor, die Verlosung ausserordentlich zu beenden.
      </p>
      <h2>Datenschutzinformationen</h2>
      <p>
        Mit der Teilnahme am Gewinnspiel wird die Einwilligung zur Kontaktaufnahme durch die Robin Heat GmbH gegeben.
        Ausführliche Datenschutzinformationen finden sie hier: <Link to="datenschutz">Datenschutz</Link>.
      </p>
    <h2>Facebook-Disclaimer</h2>
    <p>
        Facebook hat nichts mit diesem Gewinnspiel zu tun und steht auch nicht als Ansprechpartner für dieses Gewinnspiel zur Verfügung.
    </p>
    </Container>
  </Layout>
);

export default ImprintPage;
